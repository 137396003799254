import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"

const DemoStartPage = () => {
  const body = [
    {
      component: "hero_block",
      headline: "Die virtuelle Welt ist eröffnet",
      text: "Die virtuelle Welt ist eröffnet mit vielen Produktneuigkeiten und Informationen rund um die kabellose Baustelle.",
      buttons: [
        { link: "/", target: "", link_text: "Mehr erfahren" },
        { link: "/", target: "", link_text: "Jetzt eintauchen" },
      ],
      image: {
        filename: "https://picsum.photos/1920/1080",
      },
    },
    {
      component: "product_cards_block",
      headline: "Unsere beliebtesten Produkte",
      link_text: "Mehr Produkte",
      link: "/",
      product_items: [
        {
          image: "https://picsum.photos/500/500",
          headline: "ROMAX COMPACT TT",
          text: "Pressmaschine",
          variants: "8",
          rating: "4.2",
          link: "/",
        },
        {
          image: "https://picsum.photos/501/501",
          headline: "SUPERTRONIC 2000",
          text: "Gewindeschneidmaschine",
          variants: "13",
          rating: "4.7",
          link: "/",
        },
        {
          image: "https://picsum.photos/500/500",
          headline: "ROMAX COMPACT TT",
          text: "Pressmaschine",
          variants: "8",
          rating: "4.2",
          link: "/",
        },
        {
          image: "https://picsum.photos/501/501",
          headline: "SUPERTRONIC 2000",
          text: "Gewindeschneidmaschine",
          variants: "13",
          rating: "4.7",
          link: "/",
        },
      ],
    },
    {
      component: "promo_block",
      headline: "Tipps für einen erfolgreichen Tag",
      text: "Auf unserem YouTube-Kanal findest du viele Erklärvideos mit hilfreichen Tipps und Tricks rund um unsere Produkte, die dir den Arbeitsalltag noch ein Stückchen einfacher gestalten.",
      buttons: [{ link: "/", target: "", link_text: "Jetzt entdecken" }],
      image: {
        filename: "https://picsum.photos/1920/540",
      },
    },
    {
      component: "product_cards_block",
      headline: "Unsere beliebtesten Produkte",
      link_text: "Jetzt entdecken",
      link: "/",
      view: "slider",
      product_items: [
        {
          image: "https://picsum.photos/500/500",
          headline: "ROMAX COMPACT TT",
          text: "Pressmaschine",
          variants: "8",
          rating: "4.2",
          link: "/",
        },
        {
          image: "https://picsum.photos/501/501",
          headline: "SUPERTRONIC 2000",
          text: "Gewindeschneidmaschine",
          variants: "13",
          rating: "4.7",
          link: "/",
        },
        {
          image: "https://picsum.photos/500/500",
          headline: "ROMAX COMPACT TT",
          text: "Pressmaschine",
          variants: "8",
          rating: "4.2",
          link: "/",
        },
        {
          image: "https://picsum.photos/501/501",
          headline: "SUPERTRONIC 2000",
          text: "Gewindeschneidmaschine",
          variants: "13",
          rating: "4.7",
          link: "/",
        },
        {
          image: "https://picsum.photos/500/500",
          headline: "ROMAX COMPACT TT",
          text: "Pressmaschine",
          variants: "8",
          rating: "4.2",
          link: "/",
        },
      ],
    },
    {
      component: "divider_block",
    },
    {
      component: "cards_block",
      headline: "Was gibt es Neues?",
      link_text: "Weitere Neuigkeiten",
      link: "/",
      card_items: [
        {
          image: "https://picsum.photos/600/450",
          headline: "Die Sieger stehen fest!",
          text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula.",
          link: "/",
        },
        {
          image: "https://picsum.photos/600/450",
          headline: "Die Sieger stehen fest!",
          text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
          link: "/",
        },
        {
          image: "https://picsum.photos/600/450",
          headline: "Die Sieger stehen fest!",
          text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula.",
          link: "/",
        },
        {
          image: "https://picsum.photos/600/450",
          headline: "Die Sieger stehen fest!",
          text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
          link: "/",
        },
      ],
    },
  ]

  return (
    <Layout>
      <BodyBlocks body={body} />
    </Layout>
  )
}

export default DemoStartPage
